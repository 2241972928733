<template>
  <div class="menu-body"
    v-if="$oidc.isAuthenticated && $oidc.userProfile && $oidc.userProfile.roles && $oidc.userProfile.roles.length > 0">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div class="principle-dd">
        <label for="principleSelect" class="form-label page-heading-dropdown lable-text"> Principle </label>
        <select class="form-control page-heading-dropdown-content principle-select" id="principleSelect"
          v-model="principleId" @change="principleDetails()">
          <option disabled value="-1">Select Principle</option>
        </select>
      </div>
      <div class="headr-bar-menu" style="padding: 2rem; padding-bottom: 0px">Target Maturity Level</div>

      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%" @change="handleRadioChange($event)">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0">
              <label class="container">
                Level 0
                <input type="radio" class="cursorpointer" name="some-radios" value="0" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1">
              <label class="container">
                Level 1
                <input type="radio" class="cursorpointer" name="some-radios" value="1" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2">
              <label class="container">
                Level 2
                <input type="radio" class="cursorpointer" name="some-radios" value="2" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3">
              <label class="container">
                Level 3
                <input type="radio" class="cursorpointer" name="some-radios" value="3" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4">
              <label class="container">
                Level 4
                <input type="radio" class="cursorpointer" name="some-radios" value="4" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" style="border-radius: 0 4px 4px 0">
              <label class="container">
                Level 5
                <input type="radio" class="cursorpointer" name="some-radios" value="5" style="margin-right: 1rem"
                  @change="handleRadioChange($event.target.value)" />
              </label>
            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">
          {{ currentPrinciple }}
        </div>
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.practiceDescription }}</pre>
        </div>
        <div class="module-title prp-name margin-top-1em">Guidelines</div>
        <div class="behavoir-des page-content-regular" id="guidelineDescription"></div>
      </div>
      <div class="attch-con">
        <div class="module-title prp-name margin-top-1em">Attachments</div>
        <div class="row attch-row">
          <div class="col-3 attch-col page-content-regular" v-for="eachattachment in attachmentUrls"
            :key="eachattachment">
            <span>Document1 </span>
            <img alt="more" class="attch-icon" src="../../../assets/download 2.svg" />
          </div>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">Action Information</div>
        <div class="action-info-block">
          <div class="col-md-8 no-padding">
            <label for="observedCondition" class="form-label page-heading-dropdown lable-text blue-text"> Action Details
            </label>
            <textarea class="page-content-regular action-desc" id="observedCondition" name="observedCondition" rows="6"
              cols="105" ref="actionDetailTA" v-model="actionDetails"></textarea>
          </div>
          <div class="col-md-4">
            <SearchOwner :user="ownerList" :editUser="''" @userSelected="userSelected($event)"
              class="form-control page-heading-dropdown-content principle-select" style="border: transparent; padding: 0">
            </SearchOwner>
          </div>
        </div>
      </div>
      <div class="prp-info" style="display: flex; justify-content: space-between">
        <div class="col-md-8 no-padding">
          <div class="module-title prp-name">Schedule Information</div>
          <div class="schedule-info-block">
            <div>
              <label id="action-completion-date-label" for="action-completion-date-input"
                class="form-label page-heading-dropdown lable-text blue-text"> Committed Date </label>

              <b-input-group class="calender-input">
                <b-form-input id="action-completion-date-input" v-model="actionCompletionDateInput" type="text"
                  ref="actionCompletionDateIF" placeholder="Committed Date" autocomplete="off" disabled
                  class="input-target-completion-date"></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="target-completion-date-setting" v-model="actionCompletionDate" button-only right
                    locale="en-US" aria-controls="action-completion-date-input" dark :min="minDate"
                    :disabled="committedDisabled" label-no-date-selected="Committed Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }" @context="onContextT">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-6">
              <label id="action-actual-date-label" for="action-actual-date-input"
                class="form-label page-heading-dropdown lable-text blue-text"> Actual Completion Date </label>
              <b-input-group class="calender-input">
                <b-form-input id="action-actual-date-input" v-model="actionActualDateInput" type="text"
                  ref="actionActualDateIF" placeholder="Actual Completion Date" autocomplete="off" disabled
                  class="input-target-completion-date"></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker id="target-completion-date-setting" v-model="actionActualDate" button-only right
                    locale="en-US" aria-controls="action-actual-date-input" dark :min="minDate"
                    label-no-date-selected="Actual Completion Date" :date-format-options="{
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                      }" @context="onContextA">
                  </b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>
        <div class="col-md-4 no-padding other-block">
          <div class="module-title prp-name">Other</div>
          <div>
            <label for="file-input" class="form-label page-heading-dropdown lable-text blue-text"> Attachments ( Max.
              allowed 10 MB ) </label>

            <div class="fileinputs calender-input">
              <input id="file-input" type="file" class="file calender-input" placeholder="NO"
                @change="(e) => onChangeListener(e.target)" /><!-- accept="image/*" -->
              <div class="fakefile">
                <input id="file-name" class="fakefileinput" placeholder="No file chosen" />
                <span class="fakefilespan">Browse</span>
              </div>
            </div>
            <ul class="uploaded-items">
              <li class="uploaded-items-li" v-for="documentDetail in documentDetailsArr" :key="documentDetail.index">
                <span class="uploaded-file-name">
                  <a @click="downloadItem(documentDetail)">
                    {{
                      decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_")[0] +
                      decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_")[
                        decodeURIComponent(documentDetail.message).split("/").pop().split("_$^#^$_").length - 1
                      ]
                    }}
                  </a>
                </span>
                <img alt="more" class="attch-icon" src="../../../assets/bin.svg"
                  @click="removeAttachment(documentDetail.index)" />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="add-action-footer-line"></div>
      <div class="add-action-footer-info">
        <b-button v-b-modal.targetSetTargetYearModal class="discard-btn" size="lg" @click="discardAction()"> Discard
        </b-button>
        <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="onSubmit()"> Submit
        </b-button>
      </div>
    </div>
    <MessagePopup :routToBack="routToBack" class="alert" :message="errorMsg" :show="showPopup" :headText="headText" />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import Loader from "../../../components/common/Loader.vue";
import SearchOwner from "./SearchOwner.vue";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Role from "../../../common/constants/roles";
import moment from "moment";
import axios from "axios";

export default {
  name: "AddAction",
  components: {
    Breadcrumb,
    Loader,
    SearchOwner,
    MessagePopup
  },
  data() {
    return {
      roles: Role,
      routToBack: false,
      isUserAllowedToCalibrateAction: "N",
      errorMsg: "",
      headText: "",
      showPopup: false,
      showLoader: true,
      principleId: 1,
      //minDate: new Date(),
      minDate: "",
      plantId: 1,
      practiseDetailsData: [],
      attachmentUrls: [],
      targetLevel: 0,
      pillarId: null,
      practiceId: null,
      selected: 0,
      completedSteps: 75,
      totalSteps: 100,
      committedDisabled: null,
      pillarName: "People and Environment Care",
      ownerList: [],
      ownerObj: {},
      actionDetails: "",
      actionCompletionDateInput: "",
      actionCompletionDate: "",
      actionActualDateInput: "",
      actionActualDate: "",
      uploadFile: "",
      currentPrinciple: "",
      currentPrincipleDesc: "-",
      behavoiursDes: "-",
      documentIndex: 0,
      timeStamp: "",
      documentDetailsArr: [],
      practiceDescription: "-",
      updatedTimestamp: new Date(),
      allpillarId: 0
    };
  },
  beforeCreate() {
    this.$store.dispatch("breadcrumb", {
      title: "Add New Action Item: " + this.pillarName,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Plant Optimization Action Tracker / ",
          redirect: "/action",
          primary: true
        },
        { name: "Add New Action Item", primary: false }
      ]
    });
  },
  created() {
    console.log(this.$route.params);
    this.plantId = Number(this.$route.params.plantid);
    this.principleId = this.$route.params.principleid;
    this.pillarName = this.$route.params.pillarName;
    this.actionDetails = "";
    let pillar_Id = this.$route.params.pillarid.toString();

    let isAllPillar = pillar_Id.indexOf("&");
    if (isAllPillar === -1) {
      this.pillarId = Number(this.$route.params.pillarid);
    } else {
      let indx = pillar_Id.indexOf("&");
      this.allpillarId = pillar_Id.substring(indx + 1);
      this.pillarId = Number(pillar_Id.substring(0, indx));
    }

    this.getPrincipleData();
    this.getTargetDate();
    this.getOwnerList();
    this.timeStamp = new Date().toISOString();
    this.$store.dispatch("breadcrumb", {
      title: "Add New Action Item: " + this.pillarName,
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        {
          name: "Plant Optimization Action Tracker / ",
          redirect: "/action",
          primary: true
        },
        { name: "Add New Action Item", primary: false }
      ]
    });
  },
  computed: {
    // updatedTimestamp
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.message));
      const label = JSON.parse(
        JSON.stringify(
          decodeURIComponent(obj.message).split("/").pop().split("_$^#^$_")[0] +
          decodeURIComponent(obj.message).split("/").pop().split("_$^#^$_")[decodeURIComponent(obj.message).split("/").pop().split("_$^#^$_").length - 1]
        )
      );
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })
    },
    checkUserProfileAndDisableCommittedDate() {
      console.log("this.$oidc.userProfile.roles:");
      console.log(this.$oidc.userProfile.roles);
      console.log("this.$oidc.userProfile.email:");
      console.log(this.$oidc.userProfile.email);
      console.log("this.updatedTimestamp");
      console.log(this.updatedTimestamp);

      let currentUserEmail = this.$oidc.userProfile.email;
      ApiCalls.httpCallDetails(Api.MYPROFILE + currentUserEmail, "get").then((data) => {
        this.myDetails = data.data;
        this.isUserAllowedToCalibrateAction = this.myDetails.allowCalibrate;
        this.disableCommitedDate();
      });
    },
    disableCommitedDate() {
      console.log("disableCommitedDate > isUserAllowedToCalibrateAction: " + this.isUserAllowedToCalibrateAction);
      console.log("disableCommitedDate > updatedTimestamp: " + this.updatedTimestamp);
      // let date = moment(new Date(this.updatedTimestamp)).format("MM/DD/YYYY");
      // let today = moment(new Date()).format("MM/DD/YYYY");
      let date = moment(new Date(this.updatedTimestamp)).format("YYYY-MM-DD");
      let today = moment(new Date()).format("YYYY-MM-DD");
      console.log("today: " + today, "date: " + date, "moment: " + moment(date).isSameOrAfter(today));
      //if(this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) || this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER)){
      if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER) || this.isUserAllowedToCalibrateAction == "Y") {
        console.log("isGlobal or isUserAllowedToCalibrateAction");
        this.minDate = "2019-01-01";
        this.committedDisabled = false;
      } else {
        this.minDate = new Date();
        this.committedDisabled = !moment(date).isSameOrAfter(today);
      }
    },
    onContextT(ctx) {
      this.actionCompletionDateInput = ctx.selectedFormatted;
    },
    onContextA(ctx) {
      this.actionActualDateInput = ctx.selectedFormatted;
    },
    onSubmit() {
      this.showPopup = false;
      let docArray = [];
      this.documentDetailsArr.forEach((element) => {
        let url = element.message;
        let obj = {
          documentUrl: url,
          documentType: url.substring(url.lastIndexOf(".") + 1)
        };
        docArray.push(obj);
      });
      if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && this.committedDisabled) {
        this.errorMsg = "Action can not be added as the forecast setting date is passed.";
        this.headText = "";
        this.showPopup = true;
        this.routToBack = false;
      } else if (this.ownerObj == -1) {
        this.errorMsg = "Please select owner.";
        this.headText = "";
        this.showPopup = true;
        this.routToBack = false;
      } else if (this.actionDetails.length == 0 || this.actionDetails == "" || this.actionDetails == null) {
        this.errorMsg = "Please enter some text in action details.";
        this.headText = "";
        this.showPopup = true;
        this.routToBack = false;
      } else if ((this.actionCompletionDate == "" || this.actionCompletionDate == null) && !this.committedDisabled) {
        this.errorMsg = "Please select committed date.";
        this.headText = "";
        this.showPopup = true;
        this.routToBack = false;
      } else {
        let requestPayload = {
          actionDescription: this.actionDetails,
          targetCompletionDate: this.actionCompletionDate ? this.actionCompletionDate : null,
          actualCompletionDate: this.actionActualDate ? this.actionActualDate : null,
          principleId: Number(this.principleId),
          pillarId: this.pillarId,
          plantId: this.plantId,
          practiceId: this.practiceId,
          ownerUserId: this.ownerObj,
          documentDetails: docArray
        };
        if (this.actionDetails.length !== 0 && this.ownerObj != -1) {
          this.showLoader = true;
          ApiCalls.httpCallDetails(Api.CREATEADDACTION, "post", requestPayload).then((data) => {
            this.showLoader = false;
            console.log(data);
            if (data.data.message && !data.data.isAdded) {
              this.errorMsg = "New action cannot be added as this practice has been marked Completed";
              this.headText = "";
              this.showPopup = true;
              this.routToBack = true;
            } else if (this.allpillarId == -1) {
              this.pillarId = this.allpillarId;
            }
            this.$router.push("/action/add-action/" + this.plantId + "/" + this.pillarId);
          });
        }
      }
    },
    getOwnerList() {
      //   GETOWNERLIST
      ApiCalls.httpCallDetails(Api.GETOWNERLIST, "get").then((dataJSON) => {
        if (dataJSON.success) {
          this.ownerList = dataJSON.data; //.map((element) => element.userName);
          this.ownerObj = "-1";
        }
        this.showLoader = false;
      });
    },
    getTargetDate() {
      ApiCalls.httpCallDetails(Api.TARGETCOMPLETIONDATEGET + this.plantId, "get").then((dataJSON) => {
        // this.updatedTimestamp = new Date(data[0]targetLevelCompletionDate);
        let data = dataJSON.data[0];

        if (data) {
          console.log(data.targetLevelCompletionDate);
          this.updatedTimestamp = new Date(data.targetLevelCompletionDate);
          //this.disableCommitedDate();
          this.checkUserProfileAndDisableCommittedDate();
        }
      });
    },
    principleDetails() {
      let selectobj = document.getElementById("principleSelect");
      let text = selectobj.options[selectobj.selectedIndex].text;
      ApiCalls.httpCallDetails(Api.PRACTISEDETAILS + this.principleId + "/" + this.plantId, "get").then((dataJSON) => {
        if (dataJSON.success) {
          this.currentPrincipleDesc = dataJSON.data.principleDescription;
          this.practiseDetailsData = dataJSON.data.practiceList;
          this.targetLevel = dataJSON.data.principleTargetMaturityLevel;
          this.handleRadioChange(dataJSON.data.principleTargetMaturityLevel);
          this.attachmentUrls = dataJSON.data.practiceList[dataJSON.data.principleTargetMaturityLevel].attachmentUrls;
        }
      });

      this.currentPrinciple = text.split(". ")[1];
    },
    discardAction() {
      if (this.allpillarId == -1) {
        this.pillarId = this.allpillarId;
      }

      this.$router.push("/action/add-action/" + this.plantId + "/" + this.pillarId);
    },
    onChangeListener(value) {
      if (value.files[0] && value.files[0].size < 10485760) {
        document.querySelector("#file-name").value = value.files[0].name;
        let reader = new FileReader();
        reader.readAsDataURL(value.files[0]);
        reader.onloadend = () => {
          let imageSet = [];
          imageSet.push(reader.result);
          let newImageSet = [];
          newImageSet.push(value.files[0]);
          let index = this.documentIndex++;
          let timeStamp = this.timeStamp;
          let str = value.files[0].name;
          let fileName = str.substring(str.lastIndexOf("."), 0) + "_$^#^$_" + timeStamp + "_$^#^$_." + value.files[0].name.split(".")[1];
          let fileUploadFormData = new FormData();
          fileUploadFormData.append("imgsrc", newImageSet[0], fileName);
          this.showLoader = true;

          ApiCalls.httpCallDetails(Api.CREATEDOCUMENT, "post", fileUploadFormData).then((data) => {
            this.showLoader = false;
            if (data.message) {
              data.index = index;
              let url = data.message;
              data.format = url.substring(url.lastIndexOf(".") + 1);
              this.documentDetailsArr.push(data);
              document.querySelector("#file-name").value = "";
            }
          });
        };
      } else {
        console.warn("file is larger than 10MB");
      }
    },
    userSelected(evt) {
      this.ownerObj = evt;
    },
    handleRadioChange(id) {
      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
      }
      let element = document.getElementById("guidelineDescription");

      element.innerHTML = this.practiseDetailsData[parseInt(id)].guideline ? this.practiseDetailsData[parseInt(id)].guideline : "";
      this.practiceDescription = this.practiseDetailsData[parseInt(id)].practiceDescription ? this.practiseDetailsData[parseInt(id)].practiceDescription : "";
      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.practiceId = this.practiseDetailsData[parseInt(id)].practiceId;
    },
    removeAttachment(index) {
      for (let i = 0; i < this.documentDetailsArr.length; i++) {
        if (this.documentDetailsArr[i].index === index) {
          this.documentDetailsArr.splice(i, 1);
        }
      }
    },
    async getPrincipleData() {
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + this.pillarId, "get").then((dataJSON) => {
        let data = dataJSON.data;
        data.forEach((el) => {
          let principleName = el.principleName;
          let principleid = el.principleId;
          let principleNo = el.principleNumber;
          let element = document.createElement("OPTION");
          element.setAttribute("value", principleid);
          element.disabled = el.active === "Y" ? false : true;
          let t = document.createTextNode(principleNo + ". " + principleName);
          element.appendChild(t);
          document.getElementById("principleSelect").appendChild(element);
        });
        let selectobj = document.getElementById("principleSelect");
        selectobj.value = data[0].principleId;
        let text = selectobj.options[selectobj.selectedIndex].text;
        this.currentPrinciple = text.split(". ")[1];

        this.principleDetails();
      });
    }
  }
};
</script>
<style scoped>
.progress-con {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.per-text {
  color: #0f7222;
  font-weight: 600;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.asmt-info {
  border-bottom: 1px solid #dedbdb;
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  align-items: center;
}

.asmt-padding {
  margin-top: 1em;
  margin-bottom: 1em;
}

.add-action-footer-line {
  width: 98%;
  margin: 12px 0px;
  margin-left: 1%;
  border-top: 1px solid #707070b6;
}

.add-action-footer-info {
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.principle-dd {
  width: 20%;
  padding: 2rem;
  padding-bottom: 0;
}

.principle-select {
  height: 4.4rem;
  border-color: #313131;
}

.level-con {
  padding: 1em;
  /* margin-top: 1em; */
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  /* padding-top: 1em;
  padding-bottom: 1em; */
  border-radius: 6px;
}

.live-level {
  background: #eff3ff;
}

.cursorpointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}

.active-level {
  /* background: #006400; */
  background: #00428f;

  color: #fff;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #00428f;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.margin-top-1em {
  margin-top: 1em;
}

.behavoir-des {
  margin-top: 3px;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

.attch-col {
  border-radius: 6px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 5px !important;
  color: #274ba7;
}

.attch-row {
  padding-left: 1em;
  padding-right: 2em;
  margin-top: 5px;
}

.attch-icon {
  float: right;
}

.review-con {
  padding-left: 2em;
  padding-right: 2em;
}

.review-rox {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
}

.review-col {
  border: 2px solid #e2dfdf;
  border-radius: 6px;
  padding: 1em;
}

.pm-rw-con {
  margin-right: 71px;
}

.review-row {
  padding: 1em;
}

.review-type-col {
  text-align: center;
  background: #f0f7ff;
  border-radius: 6px;
  padding: 1em;
  margin-right: 10px;
}

.rw-act-con {
  text-align: right;
  margin-top: 10px;
}

.cancel-btn {
  border: 0px !important;
}

.cancel-btn:hover {
  background: #fff;
  color: #274ba7;
}

.cancel-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.save-btn {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.save-btn:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.cancel-btn:active {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.save-btn:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.blue-text {
  color: #274ba7;
}

.action-desc {
  border: #313131 1px solid;
  border-radius: 4px;
  padding: 1rem;

  resize: none;
}

.module-title {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-bottom: 9px;
}

.no-padding {
  padding: 0;
}

.action-info-block {
  display: flex;
}

.schedule-info-block {
  display: flex;
  justify-content: space-between;
}

.other-block {
  display: grid;
  justify-content: end;
}

.calender-input {
  height: 52px;
  width: 29.75rem;
  font-size: 1.166rem;
  font-family: "BarlowR", sans-serif;
}

/* calender */
.input-target-completion-date {
  border-color: #313131;
  padding: 4px;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowR", sans-serif;
}

/* calender */
.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:active {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
  font-family: "BarlowM", sans-serif !important;
  font-size: 1.166rem !important;
  margin-right: 2rem !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.uploaded-file-name {
  font: 1.166rem "BarlowM", sans-serif;
  color: #274ba7;
  margin-right: 16px;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.lable-text {
  z-index: 2;
}

div.fileinputs {
  position: relative;
  border: 1px solid #313131;
  border-radius: 4px;
}

div.fakefile {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fakefileinput {
  height: 100%;
  width: 80%;
  border: transparent;
  border-right: 1px solid #313131;
  color: #313131;
  font: 1.166rem "BarlowR", sans-serif;
  padding: 1rem;
  margin: 2px;
}

.fakefilespan {
  text-align: center;
  width: 20%;
  font: 1.166rem "BarlowSb", sans-serif;
  color: #274ba7;
}

input.file {
  position: relative;
  text-align: right;
  /* -moz-opacity:0 ;
	filter:alpha(opacity: 0); */
  opacity: 0;
  z-index: 2;
}

#action-completion-date-input:disabled {
  background: transparent;
  padding: 1rem;
}

#action-actual-date-input:disabled {
  background: transparent;
  padding: 1rem;
}

/* ------------radio btn ------------*/
.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* ------------radio btn ------------*/

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.6rem;
  left: 0.6rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.styled-input {
  width: 33.3333%;
  position: relative;
}

.styled-input label {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}

.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.review-box {
  width: 100%;
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  border: 0;
  font-size: 1rem;
}

.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
}
</style>
